import React from 'react';
import './css/Admin.css'; // Make sure to create this CSS file

const CustomerForm = ({ formData, handleInputChange, handleSubmit, submitButtonText, products }) => {

    const validateEmail = (email) => {
        // Email validation regex pattern
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (validateEmail(formData.email)) {
            handleSubmit(e);
        } else {
            alert('Invalid email address.');
        }
    };

    return (
        <form onSubmit={onSubmit} className="customer-form">
            <div>
                <label>Last Name</label>
                <input
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    placeholder="Last Name"
                />
            </div>
            <div>
                <label>First Name</label>
                <input
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    placeholder="First Name"
                />
            </div>
            <div>
                <label>Email</label>
                <input
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                    required
                />
            </div>
            <div>
                <label>Organization</label>
                <input
                    name="organization"
                    value={formData.organization}
                    onChange={handleInputChange}
                    placeholder="Organization"
                    required
                />
            </div>
            <div>
                <label>Product</label>
                <select
                    name="product"
                    value={formData.product}
                    onChange={handleInputChange}
                >
                    {products.map(product => (
                        <option key={product._id} value={product.productName}>
                            {product.productName}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>Sponsor Email</label>
                <input
                    name="sponsorEmail"
                    value={formData.sponsorEmail}
                    onChange={handleInputChange}
                    placeholder="Sponsor Email"
                />
            </div>
            <button type="submit">{submitButtonText}</button>
        </form>
    );
};

export default CustomerForm;
