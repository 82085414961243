import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

export const AuthContext = createContext({
  isAuthenticated: false,
  loading: false,
  user: {},
  setUser: () => {},
  verifyAuth: () => {},
  signOut: () => {},
  isAdmin: false, // Added isAdmin to the context
  error: null
});

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const isAdmin = user && user.roles && user.roles.includes('65abbc161218f74842ccaeea'); // Check against the admin role ID

  const verifyAuth = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/auth/verify', {
        withCredentials: true,
        headers: {
          'Cache-Control': 'no-cache'
        }
      });
      if (response.data.isAuthenticated) {
        setIsAuthenticated(true);
        setUser({...response.data.user, roles: response.data.user.roles}); // Set the user details received from the server
      } else {
        setIsAuthenticated(false);
      }
      setError(null);
      setLoading(false);
    } catch (error) {
      setIsAuthenticated(false);
      setError(error);
      setLoading(false);
    }
  };

  const isPublicRoute = (pathname) => {
    const publicPaths = ['/', '/signup', '/login'];
    return publicPaths.includes(pathname);
  };

  const isFeedbackRoute = (pathname) => {
    return pathname.startsWith('/feedback/');
  };

  const signOut = async () => {
    try {
      await axios.post('/api/auth/signout', {}, { withCredentials: true });
      setIsAuthenticated(false);
      setUser(null);
      navigate('/login'); // Redirect to the login page after signing out
    } catch (error) {
      setError(error);
      console.error('Error signing out:', error);
    }
  };

  useEffect(() => {
    // If the user is on a feedback page, do not redirect them.
    if (isFeedbackRoute(location.pathname)) {
      return;
    }
  }, [isAuthenticated, navigate, location.pathname]);

  useEffect(() => {
    const interval = setInterval(verifyAuth, 30 * 60 * 1000); // every 30 minutes
    verifyAuth(); // Initial call
    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, setUser, verifyAuth, signOut, loading, isAdmin, error }}>
      {children}
    </AuthContext.Provider>
  );
};
