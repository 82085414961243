import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { useTable, useSortBy } from 'react-table';
import API_BASE_URL from './config';
import './css/ParticipantMaintenance.css';

const ParticipantMaintenance = () => {
    const [participants, setParticipants] = useState([]);
    const [formData, setFormData] = useState({
        _id: '', email: '', relationship: '', leaderName: '', leaderEmail: '', completed: false, uniqueUrl: '', uniqueToken: '', participantName: '',
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [emailStatusMessage, setEmailStatusMessage] = useState('');
    const [isSendingEmail, setIsSendingEmail] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [participantsPerPage] = useState(10); // Adjust number of items per page as needed

    useEffect(() => {
        fetchParticipants();
    }, []);

    const fetchParticipants = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/all-participants`, { withCredentials: true });
            setParticipants(response.data);
        } catch (error) {
            console.error('Error fetching participants:', error);
        }
    }, []);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: type === 'checkbox' ? checked : value }));
    };

    const validateForm = () => {
        if (!formData.email) {
            alert('Email is required.');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        try {
            const response = await axios.post(`${API_BASE_URL}/api/participants`, formData, { withCredentials: true });
            if (response.status === 200 || response.status === 201) {
                alert('Participant added/updated successfully.');
                fetchParticipants();  // Refresh participants list
                resetForm();
            }
        } catch (error) {
            console.error('Error adding/updating participant:', error);
            alert('Failed to add/update participant.');
        }
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const resetForm = () => {
        setFormData({
            _id: '', email: '', relationship: '', leaderName: '', leaderEmail: '', completed: false, uniqueUrl: '', uniqueToken: ''
        });
        setIsEditMode(false);
    };

    const handleEditParticipant = (participant) => {
        setIsEditMode(true);
        setFormData(participant);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleDeleteParticipant = async (participantId) => {
        if (window.confirm("Are you sure you want to delete this participant?")) {
            try {
                await axios.delete(`${API_BASE_URL}/api/participants/${participantId}`, { withCredentials: true });
                alert('Participant deleted successfully.');
                fetchParticipants();  // Refresh participants list
            } catch (error) {
                console.error('Error deleting participant:', error);
                alert('Failed to delete participant.');
            }
        }
    };

    const normalizeString = (str) => {
        return str.toLowerCase().replace(/\s+/g, ' ').trim();
    };

    const filteredParticipants = useMemo(() => participants.filter(participant => {
        const participantString = `${participant.email} ${participant.relationship} ${participant.leaderName} ${participant.leaderEmail}`;
        const normalizedParticipantString = normalizeString(participantString);
        const normalizedSearchTerm = normalizeString(searchTerm);
        return normalizedParticipantString.includes(normalizedSearchTerm);
    }), [participants, searchTerm]);

    const handleSendEmail = async (participantId) => {
        setIsSendingEmail(prevState => ({ ...prevState, [participantId]: true }));
        setEmailStatusMessage(''); // Reset the status message before sending the email
        const customMessage = '';

        try {
            await axios.post(`${API_BASE_URL}/api/send-feedback-form`, { participantId, customMessage },
                { withCredentials: true });
            setEmailStatusMessage('Email sent successfully.');
            setTimeout(() => setEmailStatusMessage(''), 5000);
        } catch (error) {
            console.error('Error sending email:', error);
            setEmailStatusMessage('Failed to send email.');
            setTimeout(() => setEmailStatusMessage(''), 5000);
        } finally {
            setIsSendingEmail(prevState => ({ ...prevState, [participantId]: false })); // Stop loading regardless of success or failure
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Participant Name',
                accessor: 'participantName',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Relationship',
                accessor: 'relationship',
            },
            {
                Header: 'Leader Name',
                accessor: 'leaderName',
            },
            {
                Header: 'Leader Email',
                accessor: 'leaderEmail',
            },
            {
                Header: 'Completed',
                accessor: 'completed',
                Cell: ({ value }) => (
                    <div style={{ textAlign: 'left' }}>
                        <input type="checkbox" checked={value} readOnly />
                    </div>
                ),
            },
        ],
        [isSendingEmail]
    );

    const data = useMemo(() => filteredParticipants, [filteredParticipants]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data }, useSortBy);

    const indexOfLastParticipant = currentPage * participantsPerPage;
    const indexOfFirstParticipant = indexOfLastParticipant - participantsPerPage;
    const currentParticipants = rows.slice(indexOfFirstParticipant, indexOfLastParticipant);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const Pagination = ({ totalParticipants, participantsPerPage, paginate }) => {
        const pageNumbers = [];

        for (let i = 1; i <= Math.ceil(totalParticipants / participantsPerPage); i++) {
            pageNumbers.push(i);
        }

        return (
            <nav>
                <ul className="pagination">
                    {pageNumbers.map(number => (
                        <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <a onClick={(e) => { e.preventDefault(); paginate(number); }} href="!#" className="page-link">
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        );
    };

    return (
        <div className="participant-maintenance-container">
            <h2>Rater Maintenance</h2>
            <div className="participant-maintenance-form-container">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="participantName">Participant Name</label>
                        <input
                            type="text"
                            id="participantName"
                            name="participantName"
                            value={formData.participantName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="relationship">Relationship</label>
                        <input
                            type="text"
                            id="relationship"
                            name="relationship"
                            value={formData.relationship}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="leaderName">Leader Name</label>
                        <input
                            type="text"
                            id="leaderName"
                            name="leaderName"
                            value={formData.leaderName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="leaderEmail">Leader Email</label>
                        <input
                            type="email"
                            id="leaderEmail"
                            name="leaderEmail"
                            value={formData.leaderEmail}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="completed">Product</label>
                        <input
                            type="text"
                            id="completed"
                            name="completed"
                            checked={formData.product}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="uniqueUrl">Unique URL</label>
                        <input
                            type="text"
                            id="uniqueUrl"
                            name="uniqueUrl"
                            value={formData.uniqueUrl}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="uniqueToken">Unique Token</label>
                        <input
                            type="text"
                            id="uniqueToken"
                            name="uniqueToken"
                            value={formData.uniqueToken}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="submit"
                            value={isEditMode ? "Update Participant" : "Add Participant"}
                        />
                    </div>
                </form>
            </div>
            <div className="participant-list">
                <input
                    type="text"
                    className="search-bar"
                    placeholder="Search participants..."
                    onChange={handleSearch}
                    value={searchTerm}
                />
                <Pagination
                    totalParticipants={rows.length}
                    participantsPerPage={participantsPerPage}
                    paginate={paginate}
                />
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {currentParticipants.map(row => {
                            prepareRow(row);
                            return (
                                <React.Fragment key={row.id}>
                                    <tr {...row.getRowProps()} className="participant-row">
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                    <tr className="action-row">
                                        <td colSpan={columns.length}>
                                            <div className="action-buttons">
                                                <button onClick={() => handleEditParticipant(row.original)}>Edit</button>
                                                <button onClick={() => handleSendEmail(row.original._id)}>
                                                    {isSendingEmail[row.original._id] ? 'Sending...' : 'Send Invitation'}
                                                </button>
                                                <button onClick={() => handleDeleteParticipant(row.original.email)}>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {emailStatusMessage && <div className="email-status-message">{emailStatusMessage}</div>}
        </div>
    );
};

export default ParticipantMaintenance;
