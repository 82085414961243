import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import API_BASE_URL from './config';
import './css/AdminAIDashboard.css';

const AdminAIDashboard = () => {
    const [leaders, setLeaders] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedLeader, setSelectedLeader] = useState(null);
    const [aiResponse, setAiResponse] = useState('');
    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchLeaders();
    }, []);

    const fetchLeaders = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/customers`, { withCredentials: true });
            setLeaders(response.data);
        } catch (error) {
            console.error('Error fetching leaders:', error);
            setError('Failed to load leaders.');
        }
    }, []);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredLeaders = useMemo(() => {
        return leaders.filter(leader => {
            const leaderString = `${leader.firstName} ${leader.lastName} ${leader.email} ${leader.organization}`;
            return leaderString.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [leaders, searchTerm]);

    const handleSelectLeader = (leader) => {
        fetchLeaderAIData(leader);
    };

    const fetchLeaderAIData = async (leader) => {
        setLoading(true);
        setAiResponse('');
        try {
            const response = await axios.get(`${API_BASE_URL}/api/customer-form-responses/${leader.userId}`, {
                withCredentials: true
            });
            const leaderData = response.data.formResponses;

            // Store formResponses in selectedLeader state
            setSelectedLeader({
                ...leader,
                formResponses: leaderData
            });

            await getInitialAiAnalysis(leaderData);
        } catch (error) {
            console.error('Error fetching leader AI data:', error);
            setAiResponse('Failed to retrieve AI data.');
        } finally {
            setLoading(false);
        }
    };

    const getInitialAiAnalysis = async (data) => {
        const defaultQuestion = "Please provide a general analysis of the leadership data, highlighting any interesting trends";
    
        // Ensure that both data and a default question are sent
        const requestData = {
            data: data || [],  // Use empty array if no data
            question: defaultQuestion  // Always send a default question
        };
    
        try {
            const response = await axios.post(`${API_BASE_URL}/api/ask-ai`, {
                messages: [{
                    role: "user", 
                    content: JSON.stringify(requestData)  // Send the data and question as JSON
                }]
            }, {
                withCredentials: true
            });
            setAiResponse(response.data);
        } catch (error) {
            console.error('Error with AI analysis:', error);
            setAiResponse('Failed to get a response from AI.');
        }
    };
    
    const askAi = async () => {
        // Set a default question if the user hasn't provided one
        const defaultQuestion = "Please provide a general analysis of the leadership data, highlighting any interesting trends";
        const formattedQuestion = question.trim() === '' ? defaultQuestion : question.trim();
    
        const data = selectedLeader?.formResponses || [];
    
        setLoading(true);
        try {
            const response = await axios.post(`${API_BASE_URL}/api/ask-ai`, {
                messages: [{
                    role: "user", 
                    content: JSON.stringify({
                        data, 
                        question: formattedQuestion // Always send either a user-provided or default question
                    })
                }]
            }, {
                withCredentials: true
            });
            setAiResponse(response.data);
        } catch (error) {
            console.error('Error querying AI:', error);
            setAiResponse('Failed to get a response from AI.');
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <div className="admin-ai-dashboard">
            <h2>Admin AI Dashboard</h2>

            <div className="search-container">
                <input
                    type="text"
                    className="search-bar"
                    placeholder="Search customers/leaders..."
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </div>

            <ul className="leader-list">
                {filteredLeaders.map(leader => (
                    <li key={leader._id} onClick={() => handleSelectLeader(leader)}>
                        {leader.firstName} {leader.lastName} - {leader.email}
                    </li>
                ))}
            </ul>

            {selectedLeader && (
                <div className="leader-details">
                    <h3>{selectedLeader.firstName} {selectedLeader.lastName}</h3>
                    <p><strong>Email:</strong> {selectedLeader.email}</p>
                    <p><strong>Organization:</strong> {selectedLeader.organization}</p>
                    <p><strong>Product:</strong> {selectedLeader.product}</p>

                    <div className="ai-container">
                        <h3>AI Analysis</h3>
                        {loading ? (
                            <p className="loading">Loading AI analysis...</p>
                        ) : (
                            <p className="ai-response">{aiResponse}</p>
                        )}
                    </div>

                    <div className="question-container">
                        <textarea
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            placeholder="Ask AI about this leader's data..."
                            rows="4"
                        />
                        <button onClick={askAi} disabled={loading}>Ask AI</button>
                    </div>
                </div>
            )}

            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default AdminAIDashboard;
