import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './css/NavigationBar.module.css';
import { AuthContext } from './AuthContext';

const NavigationBar = () => {
    const { isAuthenticated, signOut, user } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSignOut = async () => {
        await signOut();
        navigate('/');
    };

    return (
        <nav className={styles.navbar}>
            <Link to={isAuthenticated ? "/main-index" : "/"} className={styles.brand}>PeopleTek Coaching</Link>
            <div className={styles.links}>
                {isAuthenticated ? (
                    <>
                        <Link to="/" className={styles.navLink}>Home</Link>
                        <Link to="/main-index" className={styles.navLink}>My Dashboard</Link>
                        <span className={styles.userInfo}>{user?.name} ({user?.email})</span>
                        <button onClick={handleSignOut} className={styles.navLink}>Sign Out</button>
                    </>
                ) : (
                    <>
                        <Link to="/signup" className={styles.navLink}>Sign Up</Link>
                        <Link to="/login" className={styles.navLink}>Login</Link>
                    </>
                )}
            </div>
        </nav>
    );
};

export default NavigationBar;
