import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar } from 'recharts';
import { AuthContext } from './AuthContext';
import styles from './css/LeaderAnalytics.module.css';
import API_BASE_URL from './config';

const LeaderAnalytics = () => {
  const [formResponses, setFormResponses] = useState([]);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext); // Assume user is provided in AuthContext
  const [aiResponse, setAiResponse] = useState('');
  const [question, setQuestion] = useState('');

  useEffect(() => {
    const fetchFormResponses = async () => {
      try {
        if (user && user.id) {
          const response = await axios.get(`${API_BASE_URL}/api/customer-form-responses/${user.id}`, {
            withCredentials: true
          });
          setFormResponses(response.data.formResponses);
          getInitialAiAnalysis(response.data.formResponses);
        }
      } catch (error) {
        console.error('Fetch form responses error:', error);
        setError(error.response?.data?.message || 'An error occurred.');
      }
    };

    fetchFormResponses();
  }, [user]);

  const getInitialAiAnalysis = async (data) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/ask-ai`, {
        messages: [{ role: "user", content: `Provide an analysis of the following data: ${JSON.stringify(data)}` }]
      }, {
        withCredentials: true
      });
      setAiResponse(response.data);
    } catch (error) {
      console.error('Error with OpenAI:', error);
      setError('Failed to get a response from AI.');
    }
  };

  const askAi = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/ask-ai`, {
        messages: [{ role: "user", content: `Given the data: ${JSON.stringify(formResponses)}, ${question}` }]
      }, {
        withCredentials: true
      });
      setAiResponse(response.data);
    } catch (error) {
      console.error('Error with OpenAI:', error);
      setError('Failed to get a response from AI.');
    }
  };

  const getChartData = () => {
    return formResponses.map((response, index) => {
      const questions = response.questions.reduce((acc, answer, i) => {
        acc[`Question ${i + 1}`] = answer;
        return acc;
      }, {});
      return {
        name: `Rater ${index + 1}`,
        ...questions
      };
    });
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <p className={styles.label}>{label}</p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name} : ${entry.value}`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.subHeader}>Leader Analytics Dashboard</h2>
      {error && <p className={styles.error}>{error}</p>}

      <div className={styles.aiContainer}>
        <h3>AI Analysis of Your Data</h3>
        {aiResponse ? (
          <p className={styles.aiResponse}>{aiResponse}</p>
        ) : (
          <p className={styles.loading}>Loading AI analysis...</p>
        )}
      </div>

      <div className={styles.chartContainer}>
        <h3>Responses by Rater</h3>
        <LineChart width={600} height={300} data={getChartData()}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 6]} ticks={[0, 1, 2, 3, 4, 5, 6]} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line type="monotone" dataKey="Question 1" stroke="#8884d8" />
          <Line type="monotone" dataKey="Question 2" stroke="#82ca9d" />
          {/* Add more lines for other questions as needed */}
        </LineChart>
      </div>

      <div className={styles.chartContainer}>
        <h3>Responses Distribution</h3>
        <BarChart width={600} height={300} data={getChartData()}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, 6]} ticks={[0, 1, 2, 3, 4, 5, 6]} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="Question 1" fill="#8884d8" />
          <Bar dataKey="Question 2" fill="#82ca9d" />
          {/* Add more bars for other questions as needed */}
        </BarChart>
      </div>

      <div className={styles.aiContainer}>
        <h3>Ask AI about your data</h3>
        <textarea
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Enter your question here"
          rows="4"
          cols="50"
        />
        <button onClick={askAi}>Ask AI</button>
      </div>
    </div>
  );
};

export default LeaderAnalytics;
