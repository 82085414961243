import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const PrivateRoute = ({ children, roleRequired }) => {
  const { isAuthenticated, user, loading } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>; // Loading indicator
  }

  // Check if user is authenticated and has the required role
  const hasRequiredRole = roleRequired ? user.roles.includes(roleRequired) : true;
  
  return isAuthenticated && hasRequiredRole ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
