import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './css/Admin.css';
import API_BASE_URL from './config';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';

const Admin = () => {
  const [customers, setCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [products, setProducts] = useState([]);
  const [expandedCustomerId, setExpandedCustomerId] = useState(null);
  const [loadingParticipants, setLoadingParticipants] = useState(false);
  const [participants, setParticipants] = useState({});
  const [isGeneratingPdf, setIsGeneratingPdf] = useState({});
  const [emailStatusMessage, setEmailStatusMessage] = useState('');
  const [customMessages, setCustomMessages] = useState({});
  const [feedbackEmailStatusMessage, setFeedbackEmailStatusMessage] = useState('');
  const [customerToExpandAfterUpdate, setCustomerToExpandAfterUpdate] = useState(null);
  const [isSendingEmail, setIsSendingEmail] = useState({});
  const [isSendingFeedback, setIsSendingFeedback] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [customersPerPage, setCustomersPerPage] = useState(10);
  const [selectedCustomerIds, setSelectedCustomerIds] = useState(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [filterProductId, setFilterProductId] = useState('');
  const [isSendingBatchEmails, setIsSendingBatchEmails] = useState(false);
  const [batchCustomMessage, setBatchCustomMessage] = useState('');
  const [selectedParticipantIds, setSelectedParticipantIds] = useState(new Set());

  const location = useLocation();
  const customerRefs = useRef({});

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEmailHistory, setSelectedEmailHistory] = useState([]);

  const openEmailHistoryModal = (emailHistory) => {
    setSelectedEmailHistory(emailHistory || []);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchCustomers();
      await fetchProducts();

      if (location.state && location.state.searchQuery) {
        const searchQuery = location.state.searchQuery;
        setSearchQuery(searchQuery);
      }
    };

    fetchData();
  }, [location]);

  useEffect(() => {
    if (searchQuery && customers.length > 0) {
      expandCustomerDetailsById(searchQuery);
    }
  }, [searchQuery, customers]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/customers`, { withCredentials: true });
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/products`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleSelectAllToggle = () => {
    if (selectAll) {
      setSelectedCustomerIds(new Set());
      setSelectAll(false);
    } else {
      const newSelection = new Set();
      filteredCustomers.forEach(customer => newSelection.add(customer._id));
      setSelectedCustomerIds(newSelection);
      setSelectAll(true);
    }
  };

  const expandCustomerDetailsById = async (customerId) => {
    const customer = customers.find(cust => cust._id === customerId);
    if (customer) {
      await toggleCustomerDetails(customer._id, customer.userId);
    }
  };

  const toggleCustomerSelection = (customerId) => {
    setSelectedCustomerIds(prev => {
      const newSet = new Set(prev);
      if (newSet.has(customerId)) {
        newSet.delete(customerId);
      } else {
        newSet.add(customerId);
      }
      return newSet;
    });
  };

  const sendBatchEmails = async () => {
    setIsSendingBatchEmails(true);
    const customerIds = Array.from(selectedCustomerIds);
    const participantIds = Array.from(selectedParticipantIds);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/admin/batch-send-feedback-form`,
        { participantIds, customMessage: batchCustomMessage },
        { withCredentials: true }
      );
      console.log('Batch email sending result:', response.data);
      setEmailStatusMessage('Batch email sent successfully.');
    } catch (error) {
      console.error('Error sending batch emails:', error);
      setEmailStatusMessage('Failed to send batch emails.');
    } finally {
      setIsSendingBatchEmails(false);
      setBatchCustomMessage('');
    }
  };

  const handleCustomerDelete = async (customerId) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/customers/${customerId}`, {
        withCredentials: true
      });
      fetchCustomers();
    } catch (error) {
      console.error('Error deleting leader:', error);
    }
  };

  const Pagination = ({ totalCustomers, customersPerPage, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalCustomers / customersPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav>
        <ul className="pagination">
          {pageNumbers.map(number => (
            <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
              <a onClick={(e) => { e.preventDefault(); paginate(number); }} href="!#" className="page-link">
                {number}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  const filteredCustomers = customers?.filter(customer => {
    const firstName = customer.firstName?.toLowerCase() || '';
    const lastName = customer.lastName?.toLowerCase() || '';
    const email = customer.email?.toLowerCase() || '';
    const id = customer._id?.toLowerCase() || '';

    const matchesSearchQuery = firstName.includes(searchQuery.toLowerCase()) ||
      lastName.includes(searchQuery.toLowerCase()) ||
      email.includes(searchQuery.toLowerCase()) ||
      id.includes(searchQuery.toLowerCase());

    const matchesProduct = filterProductId ? customer.product === filterProductId : true;

    return matchesSearchQuery && matchesProduct;
  });

  const toggleCustomerDetails = async (customerId, userId) => {
    if (expandedCustomerId === customerId) {
      setExpandedCustomerId(null);
      return;
    }

    setLoadingParticipants(true);
    try {
      let response = {};
      if (userId) {
        response = await axios.get(`${API_BASE_URL}/api/leader-participants`, {
          withCredentials: true,
          params: { leaderId: userId }
        });
      }
      setParticipants({ ...participants, [customerId]: response.data });
      setExpandedCustomerId(customerId);
      customerRefs.current[customerId].scrollIntoView({ behavior: 'smooth', block: 'center' });
    } catch (error) {
      console.error('Error fetching participants:', error);
    } finally {
      setLoadingParticipants(false);
    }
  };

  const generatePDF = async (customerId, customerEmail) => {
    setIsGeneratingPdf(prevState => ({ ...prevState, [customerId]: true }));
    try {
      const response = await axios({
        method: 'post',
        url: `${API_BASE_URL}/api/generate-pdf`,
        data: { customerId },
        responseType: 'blob',
        withCredentials: true,
      });

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      const downloadLink = document.createElement('a');
      downloadLink.href = pdfUrl;
      downloadLink.setAttribute('download', `${customerEmail}_PTEK_REPORT.pdf`);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      window.setTimeout(() => {
        URL.revokeObjectURL(pdfUrl);
      }, 100);
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Failed to generate PDF.');
    } finally {
      setIsGeneratingPdf(prevState => ({ ...prevState, [customerId]: false }));
    }
  };

  const renderCustomerList = () => {
    const indexOfLastCustomer = currentPage * customersPerPage;
    const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
    const currentCustomers = filteredCustomers.slice(indexOfFirstCustomer, indexOfLastCustomer);

    return currentCustomers.map((customer, index) => {
      const participantData = participants[customer._id] ? participants[customer._id] : [];
      const totalParticipants = participantData.length;
      const completedParticipants = participantData.filter(p => p.completed).length;
      const completionPercentage = totalParticipants > 0 ? ((completedParticipants / totalParticipants) * 100).toFixed(2) : "N/A";

      return (
        <div
          key={index}
          ref={el => (customerRefs.current[customer._id] = el)}
          className={`customer-item ${customer.readyToNotifyParticipants ? "customer-item-ready" : ""}`}
        >
          <input
            type="checkbox"
            checked={selectedCustomerIds.has(customer._id)}
            onChange={() => toggleCustomerSelection(customer._id)}
          />
          <div>
            <strong>ID:</strong> {customer._id}
          </div>
          <div>
            <strong>Name:</strong> {customer.firstName} {customer.lastName}
          </div>
          <div>
            <strong>Email:</strong> {customer.email}
          </div>
          <div>
            <strong>Product:</strong> {customer.product}
          </div>
          {participants[customer._id] && (
            <div className="completion-percentage">
              <strong>Completion Rate:</strong> {completionPercentage}%
            </div>
          )}
          {customer.readyToNotifyParticipants && (
            <div className="notification-status">
              This Leader is ready to notify raters to begin the survey!
            </div>
          )}
          <div>
            <strong>Created At:</strong>
            {customer.createdAt ? new Date(customer.createdAt).toLocaleString() : 'N/A'}
          </div>
          <div>
            {customer.emailSent ? (
              <span className="email-sent-indicator">Registration Email Sent... Resend?</span>
            ) : (
              <span className="email-not-sent-indicator">Registration Email Not Sent</span>
            )}
          </div>
          <button onClick={() => handleSendEmail(customer._id)}>
            {isSendingEmail[customer._id] ? 'Sending...' : (customer.emailSent ? 'Resend Email' : 'Email Registration')}
          </button>
          <button
            onClick={() => generatePDF(customer._id, customer.email)}
            disabled={isGeneratingPdf[customer._id]}
          >
            {isGeneratingPdf[customer._id] ? 'Generating...' : 'Generate PDF'}
          </button>
          <button onClick={() => toggleCustomerDetails(customer._id, customer.userId)}>
            {expandedCustomerId === customer._id ? 'Hide Details' : 'Show Details'}
          </button>
          {customer.emailHistory && customer.emailHistory.length > 0 ? (
            <div className="right-aligned email-history">
              <span><b>Email History: </b></span>
              <button onClick={() => openEmailHistoryModal(customer.emailHistory)}>View History</button>
            </div>
          ) : (
            <div className="right-aligned email-history">
              <span><b>Email History: </b></span>
              <button disabled>No email history</button>
            </div>
          )}
          {expandedCustomerId === customer._id && (
            <div className="customer-details">
              {loadingParticipants ? <p>Loading...</p> : renderParticipants(customer._id)}
            </div>
          )}
        </div>
      );
    });
  };

  const handleSendEmail = async (customerId) => {
    setIsSendingEmail(prevState => ({ ...prevState, [customerId]: true }));
    setEmailStatusMessage('');

    try {
      await axios.post(`${API_BASE_URL}/api/admin/email-registration`, { customerId },
        { withCredentials: true });
      setEmailStatusMessage('Registration email sent successfully.');
      fetchCustomers();
      setTimeout(() => setEmailStatusMessage(''), 5000);
    } catch (error) {
      console.error('Error sending registration email:', error);
      setEmailStatusMessage('Failed to send registration email.');
      setTimeout(() => setEmailStatusMessage(''), 5000);
    } finally {
      setIsSendingEmail(prevState => ({ ...prevState, [customerId]: false }));
    }
  };

  const sendFeedbackForm = async (participantId, customerId) => {
    setIsSendingFeedback(prevState => ({
      ...prevState,
      [customerId]: { ...(prevState[customerId] || {}), [participantId]: true }
    }));

    const customMessage = customMessages[participantId] || '';
    console.log(customMessage);

    try {
      await axios.post(`${API_BASE_URL}/api/send-feedback-form`, { participantId, customMessage },
        { withCredentials: true });
      setFeedbackEmailStatusMessage('Feedback form sent successfully.');

      setParticipants(prevParticipants => ({
        ...prevParticipants,
        [customerId]: prevParticipants[customerId].map(participant =>
          participant._id === participantId ? { ...participant, sentEmail: true } : participant
        )
      }));

      setExpandedCustomerId(customerId);

    } catch (error) {
      console.error('Error sending feedback form:', error);
      setFeedbackEmailStatusMessage('Failed to send feedback form.');
    } finally {
      setIsSendingFeedback(prevState => ({
        ...prevState,
        [customerId]: { ...(prevState[customerId] || {}), [participantId]: false }
      }));
    }
    setTimeout(() => setFeedbackEmailStatusMessage(''), 5000);
  };

  const handleCustomMessageChange = (participantId, value) => {
    setCustomMessages(prevMessages => ({
      ...prevMessages,
      [participantId]: value
    }));
  };

  const toggleParticipantSelection = (participantId) => {
    setSelectedParticipantIds(prev => {
      const newSet = new Set(prev);
      if (newSet.has(participantId)) {
        newSet.delete(participantId);
      } else {
        newSet.add(participantId);
      }
      return newSet;
    });
  };

  const renderParticipants = (customerId) => {
    const participantData = participants[customerId];
    if (!participantData || participantData.length === 0) {
      return <p className="no-raters">There are no raters assigned to this leader</p>;
    }

    return (
      <div>
        <p>Select multiple participants and click "Send Batch Feedback Forms" to send emails to all selected participants.</p>
        <p>Or use "Send Feedback Form" under each participant to send a single email.</p>
        <input
          type="checkbox"
          onChange={() => {
            const allSelected = participantData.every(p => selectedParticipantIds.has(p._id));
            const newSet = new Set(selectedParticipantIds);
            participantData.forEach(p => {
              if (allSelected) {
                newSet.delete(p._id);
              } else {
                newSet.add(p._id);
              }
            });
            setSelectedParticipantIds(newSet);
          }}
          checked={participantData.every(p => selectedParticipantIds.has(p._id))}
        />
        <label>Select All Participants</label>
        <ul style={{ listStyle: 'none' }}>
          {participants[customerId]?.map((participant, idx) => (
            <li key={idx}>
              <input
                type="checkbox"
                checked={selectedParticipantIds.has(participant._id)}
                onChange={() => toggleParticipantSelection(participant._id)}
              />
              {participant.email} - {participant.relationship} -
              {participant.completed ? (
                <span className="completed">✔ Completed Form</span>
              ) : (
                <span className="incomplete">✘ Incompleted Form</span>
              )}
              {participant.sentEmail ? (
                <span className="completed">✔ Email Sent</span>
              ) : (
                <span className="incomplete">✘ Email Not Sent</span>
              )}
              <textarea
                type="text"
                className="custom-message"
                placeholder="Custom Message"
                value={customMessages[participant._id] || ''}
                onChange={(e) => handleCustomMessageChange(participant._id, e.target.value)}
                rows={3}
              ></textarea>
              <button
                onClick={() => sendFeedbackForm(participant._id, customerId)}
                disabled={isSendingFeedback[customerId]?.[participant._id] || selectedParticipantIds.size > 1}
              >
                {isSendingFeedback[customerId]?.[participant._id] ? 'Sending...' : (participant.sentEmail ? 'Resend Feedback Form' : 'Send Feedback Form')}
              </button>
            </li>
          ))}
        </ul>
        <div className="batch-custom-message">
          <textarea
            type="text"
            className="custom-message"
            placeholder="Batch Custom Message"
            value={batchCustomMessage}
            onChange={(e) => setBatchCustomMessage(e.target.value)}
            rows={3}
          ></textarea>
        </div>
        <button
          onClick={async () => {
            const selectedIds = Array.from(selectedParticipantIds);
            await sendBatchFeedbackForms(selectedIds);
          }}
          disabled={!selectedParticipantIds.size || isSendingBatchEmails} // Disable button when sending
          className={selectedParticipantIds.size ? 'highlight' : ''}
        >
          {isSendingBatchEmails ? 'Sending...' : 'Send Batch Feedback Forms'}
        </button>
      </div>
    );
  };

  const sendBatchFeedbackForms = async (participantIds) => {
    setIsSendingBatchEmails(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/api/admin/batch-send-feedback-form`,
        { participantIds, customMessage: batchCustomMessage },
        { withCredentials: true }
      );
      console.log('Batch feedback form sending result:', response.data);
      setFeedbackEmailStatusMessage('Batch feedback forms sent successfully.');
    } catch (error) {
      console.error('Error sending batch feedback forms:', error);
      setFeedbackEmailStatusMessage('Failed to send batch feedback forms.');
    } finally {
      setIsSendingBatchEmails(false);
      setBatchCustomMessage('');
    }
  };

  return (
    <div className="admin-container">
      <h2>Rater Readiness (Admin)</h2>
      <div className="controls-section">
        <div className="search-bar-container">
          <input
            type="text"
            placeholder="Search Leaders..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="select-all-container">
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAllToggle}
          />
          <label>Select All</label>
        </div>
        <div className="filter-by-productId">
          <select
            value={filterProductId}
            onChange={(e) => setFilterProductId(e.target.value)}
          >
            {products.map(product => (
              <option key={product._id} value={product.productName}>
                {product.productName}
              </option>
            ))}
          </select>
        </div>
      </div>
      {emailStatusMessage && <div className="email-status-message">{emailStatusMessage}</div>}
      {feedbackEmailStatusMessage && (
        <div className="feedback-email-status-message">{feedbackEmailStatusMessage}</div>
      )}
      <div className="customer-list">
        <h3>Leader List</h3>
        <Pagination
          totalCustomers={filteredCustomers.length}
          customersPerPage={customersPerPage}
          paginate={setCurrentPage}
        />
        {renderCustomerList()}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Email History"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>Email History</h2>
        <button onClick={closeModal}>Close</button>
        <ul>
          {selectedEmailHistory.length > 0 ? (
            selectedEmailHistory.map((entry, index) => (
              <li key={index}>{new Date(entry.sentAt).toLocaleString()}</li>
            ))
          ) : (
            <li>No email history available</li>
          )}
        </ul>
      </Modal>
    </div>
  );
};

export default Admin;
