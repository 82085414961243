import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './css/Product.css'; // Ensure this CSS includes styles for both existing page and new form
import API_BASE_URL from './config';

const ProductPage = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({
    productName: '',
    launchDate: '',
    coachName: '',
    participants: '',
    raterInviteDate: '',
    surveyEndDate: ''
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [editProductId, setEditProductId] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/products`);
      setProducts(response.data);
    } catch (error) {
      console.error('Failed to fetch products:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditMode) {
        await axios.put(`${API_BASE_URL}/api/products/${editProductId}`, product, { withCredentials: true });
        alert('Product updated successfully!');
      } else {
        await axios.post(`${API_BASE_URL}/api/products`, product, { withCredentials: true });
        alert('Product submitted successfully!');
      }
      fetchProducts();
      resetForm();
    } catch (error) {
      console.error('Failed to submit product:', error);
      alert('Failed to submit product');
    }
  };

  const handleEdit = (product) => {
    setIsEditMode(true);
    setEditProductId(product._id);
    setProduct(product);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const resetForm = () => {
    setProduct({
      productName: '',
      launchDate: '',
      coachName: '',
      participants: '',
      raterInviteDate: '',
      surveyEndDate: ''
    });
    setIsEditMode(false);
    setEditProductId(null);
  };

  const handleRegStatus = (product) => {
    navigate('/product-registration-status', { state: { productName: product.productName } });
  };

  const handleResponseStatus = (product) => {
    navigate('/product-response-status', { state: { productName: product.productName } });
  };

  return (
    <div className="products-container">
      <div className="product-maintenance-container">
        <h1>Product Maintenance</h1>
        <form onSubmit={handleSubmit}>
          <label>
            Coach Name:
            <input type="text" name="coachName" value={product.coachName} onChange={handleChange} />
          </label>
          <label>
            Product Name:
            <input type="text" name="productName" value={product.productName} onChange={handleChange} />
          </label>
          <label>
            Number of Participants:
            <input type="number" name="participants" value={product.participants} onChange={handleChange} />
          </label>
          <label>
            Launch Date:
            <input type="date" name="launchDate" value={product.launchDate} onChange={handleChange} />
          </label>
          <label>
            Rater Invite Date:
            <input type="date" name="raterInviteDate" value={product.raterInviteDate} onChange={handleChange} />
          </label>
          <label>
            Survey End Date:
            <input type="date" name="surveyEndDate" value={product.surveyEndDate} onChange={handleChange} />
          </label>
          <button type="submit">{isEditMode ? 'Update' : 'Submit'}</button>
        </form>
      </div>

      <div className="product-list-container">
        <h2>Product List</h2>
        <table className="product-list">
          <thead>
            <tr>
              <th>Coach Name</th>
              <th>Product Name</th>
              <th>Participants</th>
              <th>Launch Date</th>
              <th>Rater Invite Date</th>
              <th>Survey End Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <React.Fragment key={product._id}>
                <tr>
                  <td>{product.coachName}</td>
                  <td>{product.productName}</td>
                  <td>{product.participants}</td>
                  <td>{new Date(product.launchDate).toLocaleDateString()}</td>
                  <td>{new Date(product.raterInviteDate).toLocaleDateString()}</td>
                  <td>{new Date(product.surveyEndDate).toLocaleDateString()}</td>
                  <td>
                    <div className="action-buttons">
                      <button onClick={() => handleEdit(product)}>Edit</button>
                      <button onClick={() => handleRegStatus(product)}>Reg Status</button>
                      <button onClick={() => handleResponseStatus(product)}>Response Status</button>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductPage;
