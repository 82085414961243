import React from 'react';
import './css/LandingPage.css'; // Ensure this path is correct

const LandingPage = () => {
  return (
    <div className="landing-container">
      <main className="landing-content">
        <h1>Welcome to PeopleTek 360 Compass</h1>
        <p>Your journey to leadership excellence starts here.</p>
        <a href="mailto:360Feedback@peopletekcoaching.com" className="contact-button">Contact Us</a>
      </main>
    </div>
  );
};

export default LandingPage;
