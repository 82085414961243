import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import styles from './css/LeaderDashboard.module.css';
import { AuthContext } from './AuthContext'; // Import AuthContext
import API_BASE_URL from './config';

const relationshipTypes = ['Self', 'Manager', 'Peer', 'Direct Report', 'Other'];

const LeaderDashboard = () => {
  const [participants, setParticipants] = useState([]);
  const [participantName, setParticipantName] = useState('');
  const [email, setEmail] = useState('');
  const [relationship, setRelationship] = useState(relationshipTypes[0]);
  const [error, setError] = useState(null);
  const [isReadyToNotify, setIsReadyToNotify] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [customerNotFound, setCustomerNotFound] = useState(false); // State to track if customer is not found
  const [autoPopulate, setAutoPopulate] = useState(true);
  const { user } = useContext(AuthContext);

  /* useEffect(() => {
    // Auto-populate email if relationship is "Self"
    if (relationship === 'Self' && user) {
      setEmail(user.email || ''); // Ensure email is never undefined
      // Use user's name if available, otherwise fall back to customer's name
      const name = user.firstName 
        ? `${user.firstName} ${user.lastName}` 
        : (customer ? `${customer.firstName} ${customer.lastName}` : '');
      setParticipantName(name || ''); // Ensure name is never undefined
    }
  }, [relationship, user, customer]); */

  useEffect(() => {
    if (relationship === 'Self' && user && autoPopulate) {
      if (email === '') {
        setEmail(user.email || ''); // Ensure email is never undefined
      }
      if (participantName === '') {
        // Use user's name if available, otherwise fall back to customer's name
        const name = user.firstName 
          ? `${user.firstName} ${user.lastName}` 
          : (customer ? `${customer.firstName} ${customer.lastName}` : '');
        setParticipantName(name || ''); // Ensure name is never undefined
      }
    }
  }, [relationship, user, customer, autoPopulate]);

  const addParticipant = async () => {
    if (!email || !participantName) {
      setError('Please enter all required fields.');
      return;
    }
  
    // Email validation
    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
  
    // Prevent adding 'Self' multiple times
    if (relationship === 'Self' && participants.some(p => p.email === user.email && p.relationship === 'Self')) {
      setError('You have already added yourself.');
      return;
    }
  
    if (relationship === 'Manager' && participants.some(p => p.relationship === 'Manager')) {
      setError('Only one manager can be added.');
      return;
    }
  
    setError(null);
  
    try {
      const payload = {
        user,
        entries: [{ email, name: participantName, relationship }],
      };
  
      const response = await axios.post(`${API_BASE_URL}/api/generate-feedback-urls`, payload);
      console.log('Response from generate-feedback-urls:', response.data);
  
      const newParticipantData = response.data[0];
      setParticipants(prevParticipants => [
        ...prevParticipants,
        {
          email: newParticipantData.email,
          participantName: newParticipantData.participantName,
          relationship,
          uniqueUrl: newParticipantData.feedbackUrl,
          completed: newParticipantData.completed,
        }
      ]);
  
      // Reset fields
      setEmail('');
      setParticipantName('');
      setRelationship(relationshipTypes[0]);
      setAutoPopulate(false); // Prevent auto-populating after reset
    } catch (error) {
      console.error('Error adding participant:', error);
      setError(error.response?.data?.message || 'An unexpected error occurred.');
    }
  };

  const handleDelete = async (participantEmail) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/participants/${participantEmail}`, {
        withCredentials: true
      });
      // Filter out the deleted participant from the participants state
      setParticipants(prevParticipants => 
        prevParticipants.filter(participant => participant.email !== participantEmail)
      );
    } catch (error) {
      console.error('Error deleting participant:', error);
      setError(error.response?.data?.message || 'An unexpected error occurred.');
    }
  };

  const validateRaters = () => {
    const counts = {
      Peer: 0,
      'Direct Report': 0,
      Other: 0,
    };
  
    participants.forEach((p) => {
      if (counts.hasOwnProperty(p.relationship)) {
        counts[p.relationship] += 1;
      }
    });
  
    const valid = Object.values(counts).filter(count => count >= 2).length >= 2;
    if (!valid) {
      setError('Please ensure at least two of "Peers", "Direct Reports", or "Others" are entered.');
      return false;
    }
  
    return true;
  };

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        // Use user's ID to fetch participants
        if (user && user.id) {
          const response = await axios.get(`${API_BASE_URL}/api/leader-participants`, { withCredentials: true });
          setParticipants(response.data);
        }
      } catch (error) {
        console.error('Fetch participants error:', error);
        setError(error.response?.data?.message || 'An error occurred.');
      }
    };

    const fetchCustomerData = async () => {
      if (user && user.id) {
        try {
          const response = await axios.get(`${API_BASE_URL}/api/customer/${user.id}`, { withCredentials: true });
          setIsReadyToNotify(response.data.readyToNotifyParticipants);
          setCustomer(response.data);
        } catch (error) {
          console.error('Error fetching customer data:', error);
          if (error.response && error.response.status === 404) {
            setCustomerNotFound(true); // Set customerNotFound to true if 404 error occurs
          } else {
            setError(error.response?.data?.message || 'An error occurred while fetching customer data.');
          }
        }
      }
    };

    fetchCustomerData();
    fetchParticipants();
  }, [user]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleReadyToNotifyChange = async (event) => {
    const isChecked = event.target.checked;
    setIsReadyToNotify(isChecked);

    if (isChecked && !validateRaters()) {
      // Prevent setting isReadyToNotify to true if validation fails
      return;
    }

    setIsReadyToNotify(isChecked);
  
    try {
      // Prepare the payload with the customer ID and the new status
      const payload = {
        readyToNotifyParticipants: isChecked
      };
  
      // Make an API call to update the customer's readyToNotifyParticipants status
      const response = await axios.put(`${API_BASE_URL}/api/update-customer-status/${user.id}`, payload, {
        withCredentials: true
      });
  
      console.log('Update status response:', response.data);
    } catch (error) {
      console.error('Error updating ready to notify status:', error);
      setError(error.response?.data?.message || 'An unexpected error occurred.');
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.subHeader}>{customer ? customer.product : 'Leader Dashboard'}</h2>
      <h2 className={styles.subHeader}>Powered by TzenAnalytics</h2>
      {error && <p className={styles.error}>{error}</p>}
      {customerNotFound && <p className={styles.error}>Customer not found. Please contact support.</p>}
      <div className={styles.inputGroup}>
        <input
          type="text"
          value={participantName || ''} // Ensure value is never undefined
          onChange={(e) => setParticipantName(e.target.value)}
          placeholder="Enter participant name"
          disabled={customerNotFound} // Disable input if customer not found
        />
        <input
          type="email"
          value={email || ''} // Ensure value is never undefined
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter participant email"
          disabled={customerNotFound} // Disable input if customer not found
        />
        <select
          value={relationship || relationshipTypes[0]} // Ensure value is never undefined
          onChange={(e) => setRelationship(e.target.value)}
          disabled={customerNotFound} // Disable select if customer not found
        >
          {relationshipTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
        <button onClick={addParticipant} disabled={customerNotFound}>Add Rater</button>
      </div>
      <div className={styles.readyToNotifyToggle}>
        <label>
          <input
            type="checkbox"
            checked={isReadyToNotify || false} // Ensure value is never undefined
            onChange={handleReadyToNotifyChange}
            disabled={customerNotFound} // Disable checkbox if customer not found
          />
          Check here when done entering your raters
        </label>
      </div>
      <ul className={styles.participantList}>
        {participants.map((participant, index) => (
          <li key={index} className={participant.completed ? styles.completed : styles.notCompleted}>
            <span className={styles.participantDetail}>{participant.participantName || ''}</span> {/* Ensure value is never undefined */}
            <span className={styles.participantDetail}>{participant.email || ''}</span> {/* Ensure value is never undefined */}
            <span className={styles.participantDetail}>{participant.relationship || ''}</span> {/* Ensure value is never undefined */}
            <button 
              className={`${styles.deleteButton} ${participant.sentEmail ? styles.disabledButton : ''}`} 
              onClick={() => participant.sentEmail ? null : handleDelete(participant.email)}
              disabled={participant.sentEmail}>
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeaderDashboard;
