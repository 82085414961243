import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from 'axios';
import { useTable, useSortBy } from 'react-table';
import CustomerForm from './CustomerForm';
import API_BASE_URL from './config';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import './css/CustomerMaintenance.css';

Modal.setAppElement('#root');

const CustomerMaintenance = () => {
    const [formData, setFormData] = useState({
        customerId: '', lastName: '', firstName: '', email: '', streetAddress: '', city: '',
        state: '', zip: '', phone: '', product: '', organization: '', sponsorLastName: '',
        sponsorFirstName: '', sponsorEmail: ''
    });
    const [isEditMode, setIsEditMode] = useState(false);
    const [leaders, setLeaders] = useState([]);
    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [emailStatusMessage, setEmailStatusMessage] = useState('');
    const [isSendingEmail, setIsSendingEmail] = useState({});
    const [isGeneratingPdf, setIsGeneratingPdf] = useState({});
    const [customMessages, setCustomMessages] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedEmailHistory, setSelectedEmailHistory] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchProducts();
        fetchLeaders();
    }, []);

    const fetchProducts = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/products`);
            setProducts(response.data);
            if (response.data.length > 0 && !formData.product) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    product: response.data[0].productName
                }));
            }
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    }, []);

    const fetchLeaders = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/customers`, { withCredentials: true });
            setLeaders(response.data);
        } catch (error) {
            console.error('Error fetching leaders:', error);
        }
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const validateForm = () => {
        if (!formData.email) {
            alert('Email is required.');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        try {
            const response = await axios.post(`${API_BASE_URL}/api/customers`, formData, { withCredentials: true });
            if (response.status === 200 || response.status === 201) {
                alert('Customer added/updated successfully.');
                fetchLeaders();  // Refresh leaders list
                resetForm();
            }
        } catch (error) {
            console.error('Error adding/updating customer:', error);
            alert('Failed to add/update customer.');
        }
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const resetForm = () => {
        setFormData({
            customerId: '', lastName: '', firstName: '', email: '', streetAddress: '', city: '',
            state: '', zip: '', phone: '', product: '', organization: '', sponsorLastName: '',
            sponsorFirstName: '', sponsorEmail: ''
        });
        setIsEditMode(false);
    };

    const handleEditLeader = (leader) => {
        setIsEditMode(true);
        setFormData({
            ...leader,
            customerId: leader._id,  // Assume the ID field from the database is _id
            organization: leader.organization || '',
            sponsorEmail: leader.sponsorEmail || 'N/A',
            product: leader.product || '',
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleDeleteLeader = async (leaderId) => {
        if (window.confirm("Are you sure you want to delete this leader?")) {
            try {
                await axios.delete(`${API_BASE_URL}/api/customers/${leaderId}`, { withCredentials: true });
                alert('Leader deleted successfully.');
                fetchLeaders();  // Refresh leaders list
            } catch (error) {
                console.error('Error deleting leader:', error);
                alert('Failed to delete leader.');
            }
        }
    };

    const normalizeString = (str) => {
        return str.toLowerCase().replace(/\s+/g, ' ').trim();
    };

    const filteredLeaders = useMemo(() => leaders.filter(leader => {
        const leaderString = `${leader.firstName} ${leader.lastName} ${JSON.stringify(leader)}`;
        const normalizedLeaderString = normalizeString(leaderString);
        const normalizedSearchTerm = normalizeString(searchTerm);
        return normalizedLeaderString.includes(normalizedSearchTerm);
    }), [leaders, searchTerm]);

    const handleRaterReadinessLink = (leaderId) => {
        navigate('/apex', { state: { searchQuery: leaderId } });
    };

    const generatePDF = async (customerId, customerEmail) => {
        setIsGeneratingPdf(prevState => ({ ...prevState, [customerId]: true })); // Start loading
        try {
            const response = await axios({
                method: 'post',
                url: `${API_BASE_URL}/api/generate-pdf`,
                data: { customerId }, // Assuming you need the customer ID for the PDF generation
                responseType: 'blob',
                withCredentials: true,
            });

            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);

            const downloadLink = document.createElement('a');
            downloadLink.href = pdfUrl;
            downloadLink.setAttribute('download', `${customerEmail}_PTEK_REPORT.pdf`); // Use customer email for file name
            document.body.appendChild(downloadLink);
            downloadLink.click();
            window.setTimeout(() => {
                URL.revokeObjectURL(pdfUrl);
            }, 100);
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('Error generating PDF:', error);
            alert('Failed to generate PDF.'); // Notify about the error
        } finally {
            setIsGeneratingPdf(prevState => ({ ...prevState, [customerId]: false })); // End loading
        }
    };

    const handleCustomMessageChange = (customerId, value) => {
        setCustomMessages(prevMessages => ({
            ...prevMessages,
            [customerId]: value
        }));
    };

    const handleSendEmail = async (customerId) => {
        const customMessage = customMessages[customerId] || '';
        setIsSendingEmail(prevState => ({ ...prevState, [customerId]: true }));
        setEmailStatusMessage(''); // Reset the status message before sending the email

        try {
            await axios.post(`${API_BASE_URL}/api/admin/email-registration`, { customerId, customMessage },
                { withCredentials: true });
            setEmailStatusMessage('Registration email sent successfully.');
            fetchLeaders(); // Fetch updated customers to reflect the change in UI
            setTimeout(() => setEmailStatusMessage(''), 5000);
        } catch (error) {
            console.error('Error sending registration email:', error);
            setEmailStatusMessage('Failed to send registration email.');
            setTimeout(() => setEmailStatusMessage(''), 5000);
        } finally {
            setIsSendingEmail(prevState => ({ ...prevState, [customerId]: false })); // Stop loading regardless of success or failure
        }
    };

    /*
    const getCompletenessColor = (completeness) => {
        if (completeness >= 75) return 'hot';
        if (completeness >= 50) return 'medium';
        return 'low';
    };
    */

    
    const getCompletenessColor = (leader) => {
        const completeness = leader.completeness;
        
        // Check if "Self" or "Manager" participants are incomplete
        const hasIncompleteSelfOrManager = leader.participants.some(participant => 
            (participant.relationship === 'Self' || participant.relationship === 'Manager') && !participant.completed
        );
        
        // Determine the color based on completeness and the status of Self/Manager participants
        if (completeness >= 60 && !hasIncompleteSelfOrManager) return 'hot'; // Green
        if (completeness >= 45 || hasIncompleteSelfOrManager) return 'medium'; // Yellow
        return 'low';
    }; 

    const isRegistered = (leader) => {
        return leader.userId ? 'Registered' : 'Not Registered';
    };

    const openEmailHistoryModal = (emailHistory) => {
        setSelectedEmailHistory(emailHistory || []); // Ensure emailHistory is defined
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ row }) => (
                    <div>{row.original.firstName} {row.original.lastName}</div>
                ),
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Organization',
                accessor: 'organization',
            },
            {
                Header: 'Sponsor',
                accessor: 'sponsorEmail',
            },
            {
                Header: 'Product',
                accessor: 'product',
            },
            {
                Header: 'Readiness',
                accessor: 'completeness',
                Cell: ({ row }) => (
                    <div className="completeness-indicator">
                        <div className={`bar ${getCompletenessColor(row.original)}`} style={{ width: `${row.original.completeness}%` }}></div>
                        <span>{row.original.completeness}%</span>
                    </div>
                ),
                sortType: (rowA, rowB, columnId, desc) => {
                    // Custom sorting function for completeness
                    const a = rowA.values[columnId];
                    const b = rowB.values[columnId];
                    return a > b ? -1 : a < b ? 1 : 0;
                }
            },
            {
                Header: 'Last Edited',
                accessor: 'createdAt',
                Cell: ({ value }) => (
                    <div>{value ? new Date(value).toLocaleDateString() : 'N/A'}</div>
                ),
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ row }) => (
                    <div>{isRegistered(row.original)}</div>
                ),
            },
        ],
        []
    );

    const data = useMemo(() => filteredLeaders, [filteredLeaders]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data }, useSortBy);

    return (
        <div className="customer-maintenance-container">
            <h2>Leader Maintenance (Admin)</h2>
            <CustomerForm
                formData={formData}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                submitButtonText={isEditMode ? "Update Leader" : "Add Leader"}
                products={products}
            />
            <div className="customer-list">
                <input
                    type="text"
                    className="search-bar"
                    placeholder="Search by product or organization..."
                    onChange={handleSearch}
                    value={searchTerm}
                />
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.isSorted ? (column.isSortedDesc ? 'sorted-desc' : 'sorted-asc') : ''}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <React.Fragment key={row.id}>
                                    <tr {...row.getRowProps()} className="leader-row">
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                    <tr className="action-row">
                                        <td colSpan={columns.length}>
                                            <div className="action-buttons">
                                                <button onClick={() => handleEditLeader(row.original)}>Edit</button>
                                                <button onClick={() => handleDeleteLeader(row.original._id)}>Delete</button>
                                                <button onClick={() => handleSendEmail(row.original._id)}>
                                                    {isSendingEmail[row.original._id] ? 'Sending...' : (row.original.emailSent ? 'Resend Email' : 'Email Registration')}
                                                </button>
                                                <button onClick={() => handleRaterReadinessLink(row.original._id)}>Rater Readiness</button>
                                                <button onClick={() => generatePDF(row.original._id, row.original.email)} disabled={isGeneratingPdf[row.original._id]}>{isGeneratingPdf[row.original._id] ? 'Generating...' : 'Generate PDF'}</button>
                                                <button className="right-aligned" onClick={() => openEmailHistoryModal(row.original.emailHistory)}>
                                                    Email History
                                                </button>
                                            </div>
                                            <textarea
                                                className="custom-message"
                                                placeholder="Enter custom message"
                                                value={customMessages[row.original._id] || ''}
                                                onChange={(e) => handleCustomMessageChange(row.original._id, e.target.value)}
                                                rows={3}
                                            ></textarea>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {emailStatusMessage && <div className="email-status-message">{emailStatusMessage}</div>}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Email History"
                className="Modal"
                overlayClassName="Overlay"
            >
                <h2>Email History</h2>
                <button onClick={closeModal}>Close</button>
                <ul>
                    {selectedEmailHistory.length > 0 ? (
                        selectedEmailHistory.map((entry, index) => (
                            <li key={index}>{new Date(entry.sentAt).toLocaleString()}</li>
                        ))
                    ) : (
                        <li>No email history available</li>
                    )}
                </ul>
            </Modal>
        </div>
    );
};

export default CustomerMaintenance;
