import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Make sure to install axios with npm or yarn
import { useParams } from 'react-router-dom'; // Import useParams hook
import styles from './css/FeedbackForm.module.css'; // Adjust the path as necessary
import API_BASE_URL from './config';

const FeedbackForm = () => {
  const { uniqueToken } = useParams(); // This hook will get the token from the URL
  const [formValues, setFormValues] = useState({
    respondentEmail: '',
    subjectEmail: '',
    relationshipToSubject: '',
    questions: new Array(36).fill(3), // Adjust as per your actual question count
    strengths: '',
    doDifferently: '',
    additionalComments: '',
    completed : false
  });
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState('');

  const questionTexts = [
    "This individual actively seeks to learn new skills to keep current with changing times.",
    "This individual recognizes when he or she has a skill gap and pursues training to address it.",
    "This individual collaborates and networks to learn new approaches.",
    "This individual is aware of or is open to suggestions about his or her own personal limitations.",
    "This individual considers points of view other than his or her own.",
    "This person understands the role he or she plays on the team in various situations.",
    "This individual is aware of his or her own unique strengths and uses them effectively to accomplish tasks at hand.",
    "This person uses feedback to know when he or she is overusing a strength.",
    "This individual recognizes and leverages the strengths of others as appropriate.",
    "This individual establishes a vision and mission, and sets the bar high for everyone involved.",
    "This individual introduces new challenges for the team with a clear vision and mission.",
    "This person behaves in a manner that is consistent with the vision and mission.",
    "This individual is welcoming and approachable.",
    "This individual is quick to give others recognition when deserved.",
    "This individual listens effectively while encouraging people to share thoughts.",
    "This individual presents a plan that is clear, concise and achievable.",
    "His or her plans provide the actions that are needed to achieve the goal or objective.",
    "This individual creates plans with clearly defined roles and responsibilities.",
    "This individual holds him or herself accountable as necessary and appropriate.",
    "This individual holds others accountable when necessary and appropriate.",
    "This individual takes responsibility and promotes others to do the same.",
    "Shows empathy and listens to others during stressful situations involving conflict.",
    "This person challenges the group to address issues when there are strong disagreements about approach.",
    "This individual promotes opportunities for improvement when teams are struggling to find common ground.",
    "This individual influences others while being respectful, positive and enthusiastic.",
    "This individual presents his or herself confidently.",
    "This individual provides a calming influence in a heated situation.",
    "This person networks and reaches out to others beyond the immediate group.",
    "This person is open and authentic.",
    "This person helps foster a mutual respect.",
    "This individual puts forth extra effort to seek out input from others to improve him or herself.",
    "This individual seeks feedback from others to improve products, processes or procedures.",
    "This person encourages others to seek feedback as a part of their development.",
    "This individual effectively inspires others to stretch beyond their comfort zones.",
    "This person presents new ideas in a way that energizes and excites.",
    "Individuals feel valued and inspired by this person."
  ];

  const ratingLegend = {
    0: '0: Don\'t Know',
    1: '1: Never',
    2: '2: Rarely',
    3: '3: Sometimes',
    4: '4: Often',
    5: '5: Always'
  };  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...formValues.questions];
    updatedQuestions[index] = value;
    setFormValues({ ...formValues, questions: updatedQuestions });
  };

  useEffect(() => {
    const fetchFeedbackDetails = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/feedback/${uniqueToken}`);
        // Prepopulate the email and relationship in the form state
        if (response.data.completed) {
          setIsFormCompleted(true); // Set this to true if the form is already completed
        }
        setFormValues(values => ({
          ...values,
          respondentEmail: response.data.email,
          relationshipToSubject: response.data.relationship,
          subjectEmail: response.data.leaderEmail,
          completed: response.data.completed
        }));
      } catch (error) {
        console.error('Error fetching feedback details:', error);
        // Handle error appropriately
      }
    };

    fetchFeedbackDetails();
  }, [uniqueToken]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Increment each question's rating value by 1 in place
    // this is a really silly thing and i just need a long term solution
    // for some reason this project, the data all originally was incremented by 1 (all the rating values)
    // so our pdf generated subtracts one from all of them
    // so here we just add one :/ dumb i know, but i'm lazy right now
    const updatedQuestions = formValues.questions.map(question => 
      question > 0 ? question + 1 : question // Only increment if the value is greater than 0
    );

    // Update the formValues with the incremented questions
    const updatedFormValues = {
      ...formValues,
      questions: updatedQuestions
    };

    try {
      const submissionPayload = {
        ...updatedFormValues,
        uniqueToken, // Include the unique token in the submission payload
      };
      const response = await axios.post(`${API_BASE_URL}/api/submit-feedback`, submissionPayload);
      // ... handle the response as needed
      if (response.status === 200) {
        setSubmissionStatus('Feedback submitted successfully.');
        setIsFormCompleted(true);
      } else {
        setSubmissionStatus('Failed to submit feedback.');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      // ... handle the error as needed
      setSubmissionStatus('An error occurred while submitting feedback.');
    }
  };


  if (isFormCompleted) {
    return (
      <div className={styles.formContainer}>
        <p>You've already successfully completed this form. Thank you!</p>
      </div>
    );
  }

  return (
    <div className={styles.formContainer}>
    <h1> PeopleTek 360 Compass </h1>
    <form onSubmit={handleSubmit}>
      <div className={styles.formGroup}>
        <label htmlFor="respondentEmail">Your Email</label>
        <input
          id="respondentEmail"
          type="email"
          name="respondentEmail"
          value={formValues.respondentEmail}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className={styles.formGroup}>
        <label>Person's Email For Which You Are Providing Feedback</label>
        <input
          type="email"
          name="subjectEmail"
          value={formValues.subjectEmail}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className={styles.formGroup}>
        <label>Your Relationship to the Person</label>
        <select
          name="relationshipToSubject"
          value={formValues.relationshipToSubject}
          onChange={handleInputChange}
          required
        >
          <option value="">Select Relationship</option>
          <option value="Self">Self</option>
          <option value="Manager">Manager</option>
          <option value="Peer">Peer</option>
          <option value="Direct Report">Direct Report</option>
          <option value="Other">Other</option>
        </select>
      </div>

      {/* Radio button questions */}
      {formValues.questions.map((value, index) => (
        <div key={index} className={styles.questionGroup}>
          <p className={styles.questionLabel}>{questionTexts[index]}</p>
          <div className={styles.radioOptions}>
            {Object.entries(ratingLegend).map(([number, label]) => (
              <div key={number} className={styles.radioContainer}>
                <div className={styles.legendText}>{label.split(':')[1]}</div>
                <input
                  id={`question-${index}-${number}`}
                  type="radio"
                  name={`question-${index}`}
                  value={number}
                  checked={value === parseInt(number, 10)}
                  onChange={() => handleQuestionChange(index, parseInt(number, 10))}
                />
                <label htmlFor={`question-${index}-${number}`} className={styles.radioLabel}>
                  {number}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}

      {/* Open-ended questions */}
      <div className={styles.formGroup}>
        <label>Top 2-3 Strengths</label>
        <textarea
          name="strengths"
          value={formValues.strengths}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className={styles.formGroup}>
        <label>One Thing to Do Differently</label>
        <textarea
          name="doDifferently"
          value={formValues.doDifferently}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className={styles.formGroup}>
        <label>Additional Comments</label>
        <textarea
          name="additionalComments"
          value={formValues.additionalComments}
          onChange={handleInputChange}
        />
      </div>

      <button type="submit" className={styles.submitButton}>Submit Feedback</button>
    </form>
    {submissionStatus && <p className={styles.submissionStatus}>{submissionStatus}</p>}
  </div>
  );
};

export default FeedbackForm;
