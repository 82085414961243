import React, { useState } from 'react';
import axios from 'axios';
import './css/signup.css';
import API_BASE_URL from './config';

const SignUp = () => {
  const [user, setUser] = useState({
    username: '',
    email: '',
    password: ''
  });
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/api/auth/signup`, user);
      console.log(response.data);
      setSuccessMessage('Registration successful! Please log in.');
      // Optionally, clear the form or redirect to the login page
      // window.location = '/signin';
    } catch (error) {
      console.error(error.response ? error.response.data : error);
      setSuccessMessage(''); // Clear any existing success message on error
    }
  };

  return (
    <div className="signup-container">
      <form className="signup-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="username"
          value={user.username}
          onChange={handleChange}
          placeholder="Username"
          required
        />
        <input
          type="email"
          name="email"
          value={user.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <input
          type="password"
          name="password"
          value={user.password}
          onChange={handleChange}
          placeholder="Password"
          required
        />
        <button type="submit">Sign Up</button>
        {successMessage && <div className="success-message">{successMessage}</div>}
      </form>
    </div>
  );
};

export default SignUp;
